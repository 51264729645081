import axios from '@axios'

import httpService from "../../../http/requests/app"

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchChatRecipients(){
      return new Promise((resolve,reject) => {
        httpService.getShortCodeRecipients()
            .then(response => {

              resolve(response.data);

            })
            .catch(error => {

              //reject({message: axios.defaults.headers})
            })
      });
    },
    sendShortCodeMessage(ctx, messageData){
      return new Promise((resolve,reject) => {
        httpService.sendShortCodeMessage(messageData)
            .then(response => {

              resolve(response.data);

            })
            .catch(error => {

              //reject({message: axios.defaults.headers})
            })
      });
    },
    fetchChatsAndContacts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/chat/chats-and-contacts')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProfileUser() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/chat/users/profile-user')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchShortCodeChat(ctx, { recipient }){
      return new Promise((resolve,reject) => {
        httpService.getShortCodeChat(recipient)
            .then(response => {

              resolve(response.data);

            })
            .catch(error => {

              //reject({message: axios.defaults.headers})
            })
      });
    },

    getChat(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/chat/chats/${userId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sendMessage(ctx, { contactId, message, senderId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/chat/chats/${contactId}`, { message, senderId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
